.supplier-invoice {
  min-width: 500px;
  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .features-list {
    border-bottom: 1px solid #e4ebf3;
    padding: 80px 30px;
    position: relative;
  }

  .container {
    width: 100%;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  .features-wrapper-two {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .features-left {
    max-width: 40%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .features-paragraph {
    margin-bottom: 24px;
  }

  .text-link-arrow {
    color: #1a1b1f;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    display: flex;
  }

  .arrow-embed {
    margin-left: 2px;
    display: flex;
  }

  .features-right {
    max-width: 46%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -16px;
    display: flex;
  }

  .features-block-two {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    display: flex;
  }

  .features-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 16px;
  }

  .grid {
    grid-column-gap: 0px;
    border: 1px #000;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  }

  .div-block {
    min-width: 0;
  }

  .collapsebutton {
    width: 35px;
    height: 75px;
    background-color: #ffbb27;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: 40%;
    bottom: auto;
    left: -35px;
    cursor: pointer;
  }

  .div-block-2 {
    width: auto;
    height: 100vh;
    box-shadow: -3px 0 18px 10px rgba(0, 0, 0, 0.2);
  }

  .image {
    width: 25px;
    height: 25px;
  }

  .left-arrow {
    margin-left: 15px;
    display: none;
  }

  .right-arrow {
    display: block;
  }

  .div-block-3 {
    width: 100%;
    height: 100%;
    margin-top: -75px;
    position: static;
    overflow: auto;
  }

  .div-block-4 {
    width: auto;
    max-width: none;
    padding-left: 35px;
    padding-right: 35px;
    position: static;
    overflow: auto;
  }

  .div-block-5 {
    height: 90%;
    min-width: 30rem;
    margin: 5% 20px;
  }

  .div-block-6 {
    width: 75px;
    height: 25px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    position: relative;
    top: 4%;
    left: 20px;
  }

  .text-block {
    font-size: 12px;
  }

  .image-2 {
    width: 20px;
    height: 20px;
  }

  .div-block-7 {
    border-bottom: 1px solid #bcbcbc;
    justify-content: space-between;
    display: flex;
  }

  .section {
    width: auto;
    margin-top: 20px;
  }

  .text-block-2 {
    height: auto;
    border-right: 1px solid #bcbcbc;
    margin-top: 0;
    margin-right: 0;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .text-block-3,
  .text-block-4,
  .text-block-5 {
    font-size: 10px;
  }

  .text-block-6 {
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 20px;
    font-size: 10px;
  }

  .div-block-8 {
    margin-left: 20px;
  }

  .div-block-9 {
    border-bottom: 1px #bcbcbc;
    padding-right: 20px;
    display: flex;
  }

  .button {
    background-color: #f7f8f8;
    border: 1px solid #000;
    padding: 12px 14px;
  }

  .div-block-10 {
    width: 20rem;
    justify-content: space-between;
    display: flex;
  }

  .buttonarrow {
    width: 35px;
    height: 25px;
    cursor: pointer;
    border: 1px solid #484848;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    display: flex;
  }

  .div-block-11 {
    display: flex;
  }

  .image-3 {
    width: 15px;
    height: 15px;
    min-width: 0;
    margin-left: 6px;
  }

  .image-4 {
    width: 15px;
    height: 15px;
  }

  .text-block-7 {
    font-size: 12px;
  }

  .text-block-8 {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
  }

  .section-2 {
    max-width: 100%;
    border: 0.5px #bcbcbc;
    margin-top: 40px;
  }

  .section-3 {
    margin-top: 60px;
  }

  .div-block-12 {
    justify-content: flex-end;
    display: flex;
  }

  .greenbutton {
    cursor: pointer;
    background-color: #868686;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    padding: 5px 15px;
    display: flex;
  }

  .greenbutton.greenbtn {
    background-color: #007533;
    margin-right: 60px;
  }

  .greenbutton.greenbtn.withoutleft {
    margin-left: 0;
    margin-right: 0;
  }

  .textblock10 {
    color: #fff;
    font-weight: 700;
  }

  .grid-2 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    outline-offset: 0px;
    outline: 3px #333;
    grid-template-rows: 38px 38px 38px 38px;
    grid-template-columns: 88px 0.5fr 0.5fr 99px 109px;

    * {
      font-size: 11px !important;
    }
  }

  .text-block-9 {
    overflow: hidden;
    text-align: center;
    border: 1px solid #bcbcbc;

    padding-top: 5px;
  }

  .text-block-9.totaltextblock {
    text-align: right;
    border-color: #bcbcbc;
  }

  .text-block-9.totaltextblock.bottomline {
    border-top-color: #000;
  }

  .text-block-9.totalnum {
    font-weight: 700;
  }

  .text-block-9.totalnum.bottomline {
    border-top-color: #000;
  }

  .text-block-9.topline {
    border-color: #bcbcbc #bcbcbc #000;
    strong {
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;
    }
    padding-top: 4px;
  }

  .text-block-9.bottomline {
    border-top-color: #000;
  }

  .image-5 {
    width: 15px;
    height: 15px;
  }

  .div-block-13 {
    cursor: pointer;
    border: 1px solid #bcbcbc;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .div-block-13.bottomline {
    border-top-color: #000;
  }

  .div-block-14 {
    border: 1px solid #000;
  }

  .div-block-14.topline {
    border-color: #bcbcbc #bcbcbc #000;
  }

  .div-block-15 {
    border: 1px solid #000;
  }

  .div-block-15.topline {
    border-color: #bcbcbc #bcbcbc #000;
  }

  .div-block-16,
  .div-block-17 {
    border: 1px solid #bcbcbc;
  }

  .div-block-17.bottomline {
    border-top-color: #000;
  }

  .div-block-17.bottomline.empty {
    border-bottom-style: none;
  }

  .subscript,
  .subscript-2 {
    margin-right: 10px;
  }

  .div-block-18 {
    border: 1px solid #bcbcbc;
  }

  .div-block-18.empty {
    opacity: 1;
    border-style: none;
    border-color: rgba(0, 0, 0, 0);
  }

  .div-block-19 {
    border: 1px solid #000;
  }

  .submit-button {
    background-color: #007533;
  }

  .text-field,
  .text-field-2,
  .text-field-3 {
    height: 20px;
  }

  .field-label,
  .field-label-2 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
  }

  .div-block-20 {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    display: flex;
  }

  .select-field {
    height: 20px;
  }

  .image-6 {
    width: auto;
    height: auto;
  }

  .empty,
  .div-block-21 {
    border: 1px #000;
  }

  @media screen and (max-width: 991px) {
    .container {
      max-width: 728px;
    }

    .features-wrapper-two {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .features-left {
      max-width: 100%;
      margin-bottom: 40px;
    }

    .features-right {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .features-list {
      padding: 60px 15px;
    }

    .div-block-2 {
      display: none;
    }
  }

  @media screen and (max-width: 479px) {
    .container {
      max-width: none;
    }

    .features-right {
      margin-bottom: -30px;
    }

    .features-block-two {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    .features-image {
      margin-bottom: 10px;
    }
  }

  #w-node-dcb9a9c7-4692-acaa-daf5-c24c17b0992c-33a6b44a,
  #w-node-_82ada753-bc53-edd3-6fd4-e11e36169679-33a6b44a,
  #w-node-e025d291-bc92-c891-5f19-ac09642b692a-33a6b44a,
  #w-node-dfb44942-320d-7708-b68e-1641f3dda1d4-33a6b44a,
  #w-node-c5e1fa4a-eb8c-c51c-3219-c23f8cd3e27f-33a6b44a,
  #w-node-ad06883e-99be-f831-0822-1119c7c70618-33a6b44a,
  #w-node-ee434af0-ee4a-878d-b82a-393163c3eabe-33a6b44a,
  #w-node-_169b56d5-f6c4-42fb-46b6-302dce3ed2fe-33a6b44a,
  #w-node-_9453af01-44b0-dc86-23cd-9d02b7e092be-33a6b44a,
  #w-node-_9368b67e-6e36-2f17-9c3f-542f61d5f9db-33a6b44a,
  #w-node-d692d1b7-cf58-018d-8b1d-e115e3d11f69-33a6b44a,
  #w-node-_7aa3fe38-3a7e-1f5e-bfc2-981d1cf7c54a-33a6b44a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_12859862-e42a-ac1f-561b-97c6c9559d68-33a6b44a,
  #w-node-c225420e-48d7-3ffe-cfaf-0bd3a6726b12-33a6b44a {
    grid-area: span 1 / span 2 / span 1 / span 3;
  }

  #w-node-_0ecbe127-e72e-a2fb-bd52-8e8bd75cc7ac-33a6b44a,
  #w-node-e8e3e17c-c708-e203-d5a9-4c9a8a7d0480-33a6b44a,
  #w-node-_3eeb49b9-dd73-7254-9d67-cddaf849554e-33a6b44a,
  #w-node-ded258eb-bea2-f3fb-e9fa-9fac656e6f74-33a6b44a,
  #w-node-_637a1e9e-523e-4c5a-69b8-bbd204c3b9b3-33a6b44a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}
