.distributionprice {
  .text-block-13 {
    * {
      font-size: 12px !important;
    }
  }
  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .features-list {
    border-bottom: 1px solid #e4ebf3;
    padding: 80px 30px;
    position: relative;
  }

  .container {
    width: 100%;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  .features-wrapper-two {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .features-left {
    max-width: 40%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .features-paragraph {
    margin-bottom: 24px;
  }

  .text-link-arrow {
    color: #1a1b1f;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    display: flex;
  }

  .arrow-embed {
    margin-left: 2px;
    display: flex;
  }

  .features-right {
    max-width: 46%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -16px;
    display: flex;
  }

  .features-block-two {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    display: flex;
  }

  .features-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 16px;
  }

  .grid {
    grid-column-gap: 0px;
    border: 1px #000;
    grid-template-rows: auto;
    grid-template-columns: auto 0;
  }

  .div-block {
    min-width: 0;
  }

  .collapsebutton {
    width: 35px;
    height: 75px;
    background-color: #ffbb27;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: 40%;
    bottom: auto;
    left: -35px;
  }

  .div-block-2 {
    width: auto;
    height: 100vh;
    box-shadow: -3px 0 18px 10px rgba(0, 0, 0, 0.2);
  }

  .image {
    width: 25px;
    height: 25px;
  }

  .left-arrow {
    margin-left: 15px;
    display: none;
  }

  .right-arrow {
    display: block;
  }

  .div-block-3 {
    width: 100%;
    height: 100%;
    margin-top: -75px;
    position: static;
    overflow: auto;
  }

  .div-block-4 {
    width: auto;
    max-width: none;
    padding-left: 35px;
    padding-right: 35px;
    position: static;
    overflow: auto;
  }

  .div-block-5 {
    height: 90%;
    min-width: 30rem;
    margin: 5% 20px;
  }

  .div-block-6 {
    width: 75px;
    height: 25px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    position: relative;
    top: 4%;
    left: 20px;
  }

  .text-block {
    font-size: 12px;
  }

  .image-2 {
    width: 20px;
    height: 20px;
  }

  .div-block-7 {
    border-bottom: 1px solid #bcbcbc;
    justify-content: space-between;
    display: flex;
  }

  .section {
    width: auto;
    margin-top: 20px;
  }

  .text-block-2 {
    height: auto;
    border-right: 1px solid #bcbcbc;
    margin-top: 0;
    margin-right: 0;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 700;
  }

  .text-block-3,
  .text-block-4,
  .text-block-5 {
    font-size: 10px;
  }

  .text-block-6 {
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 20px;
    font-size: 10px;
  }

  .div-block-8 {
    margin-left: 20px;
  }

  .div-block-9 {
    border-bottom: 1px #bcbcbc;
    padding-right: 20px;
    display: flex;
  }

  .button {
    background-color: #f7f8f8;
    border: 1px solid #000;
    padding: 12px 14px;
  }

  .div-block-10 {
    width: 20rem;
    justify-content: space-between;
    display: flex;
  }

  .buttonarrow {
    width: 35px;
    height: 25px;
    cursor: pointer;
    border: 1px solid #484848;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    display: flex;
  }

  .div-block-11 {
    display: flex;
  }

  .image-3 {
    width: 15px;
    height: 15px;
    min-width: 0;
    margin-left: 6px;
  }

  .image-4 {
    width: 15px;
    height: 15px;
  }

  .yellowcirclebutton {
    width: 80px;
    height: 30px;
    grid-column-gap: 3px;
    grid-row-gap: 3px;
    background-color: #ffbb27;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    display: flex;
  }

  .text-block-7 {
    font-size: 12px;
  }

  .text-block-8 {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
  }

  .section-2 {
    max-width: 100%;
    border: 0.5px #bcbcbc;
    margin-top: 40px;
  }

  .section-3 {
    margin-top: 60px;
  }

  .div-block-12 {
    justify-content: flex-end;
    display: flex;
  }

  .greenbutton {
    cursor: pointer;
    background-color: #868686;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    padding: 5px 15px;
    display: flex;
  }

  .greenbutton.greenbtn {
    background-color: #95c2a8;
    margin-right: 60px;
  }

  .greenbutton.greenbtn.withoutleft {
    margin-left: 0;
    margin-right: 0;
  }

  .textblock10 {
    color: #fff;
    font-weight: 700;
  }

  .grid-2 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    outline-offset: 0px;
    outline: 3px #333;
    grid-template-rows: 25px 25px 25px 25px;
    grid-template-columns: 88px 89px 0.5fr 0.5fr 99px 109px 20px 20px 20px;
  }

  .text-block-9 {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  .text-block-9.totaltextblock {
    text-align: right;
    border-color: #bcbcbc;
  }

  .text-block-9.totaltextblock.bottomline {
    border-top-color: #000;
  }

  .text-block-9.totalnum {
    font-weight: 700;
  }

  .text-block-9.totalnum.bottomline {
    border-top-color: #000;
  }

  .text-block-9.topline {
    border-color: #bcbcbc #bcbcbc #000;
  }

  .text-block-9.bottomline {
    border-top-color: #000;
  }

  .image-5 {
    width: 15px;
    height: 15px;
  }

  .div-block-13 {
    cursor: pointer;
    border: 1px solid #bcbcbc;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .div-block-13.bottomline {
    border-top-color: #000;
  }

  .div-block-14 {
    border: 1px solid #000;
  }

  .div-block-14.topline {
    border-color: #bcbcbc #bcbcbc #000;
  }

  .div-block-15 {
    border: 1px solid #000;
  }

  .div-block-15.topline {
    border-color: #bcbcbc #bcbcbc #000;
  }

  .div-block-16,
  .div-block-17 {
    border: 1px solid #bcbcbc;
  }

  .div-block-17.bottomline {
    border-top-color: #000;
  }

  .div-block-17.bottomline.empty {
    border-bottom-style: none;
  }

  .subscript,
  .subscript-2 {
    margin-right: 10px;
  }

  .div-block-18 {
    border: 1px solid #bcbcbc;
  }

  .div-block-18.empty {
    opacity: 1;
    border-style: none;
    border-color: rgba(0, 0, 0, 0);
  }

  .div-block-19 {
    border: 1px solid #000;
  }

  .submit-button {
    background-color: #95c2a8;
  }

  .text-field,
  .text-field-2,
  .text-field-3 {
    height: 20px;
  }

  .field-label,
  .field-label-2 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
  }

  .div-block-20 {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    display: flex;
  }

  .select-field {
    height: 20px;
  }

  .image-6 {
    width: auto;
    height: auto;
  }

  .empty,
  .div-block-21 {
    border: 1px #000;
  }

  .inputfield {
    height: 24px;
    color: #919191;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 5px;
  }

  .inputfield.etafield {
    background-color: #fff;
  }

  .field-label-3 {
    font-size: 12px;
    font-weight: 400;
  }

  .div-block-22 {
    height: 25px;
    color: #484848;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    flex: 1;
    justify-content: center;
    align-items: baseline;
    padding: 1px 0 0;
    font-size: 12px;
    display: flex;
  }

  .div-block-22.select {
    background-color: #ededed;
  }

  .div-block-23 {
    width: 73%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .div-block-24 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .div-block-24.w50 {
    width: 100%;
  }

  .grid-3 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: 36px 36px;
    grid-template-columns: 0.25fr 1fr 1fr 0.75fr 1fr;
  }

  .griddiv3 {
    border: 1px solid #ddd;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    display: flex;
  }

  .griddiv3.tableheader {
    text-transform: uppercase;
    border-bottom-color: #000;
    font-weight: 700;
  }

  .section-4 {
    margin-top: 50px;
    overflow: visible;
  }

  .section-5 {
    margin-top: 50px;
  }

  .section-6 {
    margin-top: 50px;
    overflow: auto;
  }

  .grid4 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: 36px 36px;
    grid-template-columns: 0.25fr 1fr 1fr 1fr;
  }

  .grid5 {
    min-width: 1400px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: 36px 36px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .text-block-10 {
    text-align: right;
    font-size: 12px;
  }

  .text-block-10.bold {
    font-weight: 700;
  }

  .text-block-11 {
    margin-left: 24px;
    padding-top: 2px;
    padding-left: 0;
    display: block;
    position: absolute;
  }

  .div-block-25 {
    height: 20px;
    border-bottom: 1px solid #ededed;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .div-block-25.nop {
    border-bottom-style: none;
  }

  .image-7 {
    margin-left: 10px;
  }

  .image-8 {
    margin-left: 0;
    padding-left: 0;
  }

  .thirtyf {
    width: 40%;
  }

  .div-block-26 {
    width: 65%;
  }

  .div-block-27 {
    margin-bottom: 15px;
    padding-bottom: 0;
  }

  .text-block-12 {
    text-align: right;
  }

  .text-block-12.b {
    color: #df8138;
    font-size: 18px;
  }

  .div-block-28 {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
    justify-content: flex-end;
    padding-right: 10px;
    display: flex;
  }

  .div-block-28.x {
    margin-top: 24px;
  }

  .grid-4 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .cell {
    border: 1px solid #000;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .cell.borderless {
    border-style: none;
  }

  .text-block-13 {
    text-align: center;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
  }

  .text-block-13.title {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 700;
  }

  @media screen and (max-width: 991px) {
    .container {
      max-width: 728px;
    }

    .features-wrapper-two {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .features-left {
      max-width: 100%;
      margin-bottom: 40px;
    }

    .features-right {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .features-list {
      padding: 60px 15px;
    }

    .div-block-2 {
      display: none;
    }
  }

  @media screen and (max-width: 479px) {
    .container {
      max-width: none;
    }

    .features-right {
      margin-bottom: -30px;
    }

    .features-block-two {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    .features-image {
      margin-bottom: 10px;
    }
  }

  #w-node-dcb9a9c7-4692-acaa-daf5-c24c17b0992c-33a6b44a,
  #w-node-_82ada753-bc53-edd3-6fd4-e11e36169679-33a6b44a,
  #w-node-e025d291-bc92-c891-5f19-ac09642b692a-33a6b44a,
  #w-node-dfb44942-320d-7708-b68e-1641f3dda1d4-33a6b44a,
  #w-node-c5e1fa4a-eb8c-c51c-3219-c23f8cd3e27f-33a6b44a,
  #w-node-ad06883e-99be-f831-0822-1119c7c70618-33a6b44a,
  #w-node-ee434af0-ee4a-878d-b82a-393163c3eabe-33a6b44a,
  #w-node-_169b56d5-f6c4-42fb-46b6-302dce3ed2fe-33a6b44a,
  #w-node-_9453af01-44b0-dc86-23cd-9d02b7e092be-33a6b44a,
  #w-node-_9368b67e-6e36-2f17-9c3f-542f61d5f9db-33a6b44a,
  #w-node-d692d1b7-cf58-018d-8b1d-e115e3d11f69-33a6b44a,
  #w-node-_7aa3fe38-3a7e-1f5e-bfc2-981d1cf7c54a-33a6b44a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_12859862-e42a-ac1f-561b-97c6c9559d68-33a6b44a,
  #w-node-c225420e-48d7-3ffe-cfaf-0bd3a6726b12-33a6b44a {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-_0ecbe127-e72e-a2fb-bd52-8e8bd75cc7ac-33a6b44a,
  #w-node-e8e3e17c-c708-e203-d5a9-4c9a8a7d0480-33a6b44a,
  #w-node-_3eeb49b9-dd73-7254-9d67-cddaf849554e-33a6b44a,
  #w-node-ded258eb-bea2-f3fb-e9fa-9fac656e6f74-33a6b44a,
  #w-node-_637a1e9e-523e-4c5a-69b8-bbd204c3b9b3-33a6b44a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-a0a2905c-e0ea-2c3b-b696-28e2ea9e0460-e6c45078 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: auto;
    justify-self: auto;
  }

  #w-node-_80b87461-4ccc-11bf-b274-2d4f5d83830b-e6c45078,
  #w-node-f3b5430e-8b06-e7b1-793c-ce20b91ddc17-e6c45078 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: auto;
    justify-self: auto;
  }

  #w-node-ed2f4118-8c75-01d7-8943-90e63c5ada22-e6c45078 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: auto;
    justify-self: auto;
  }

  #w-node-a5dd87d6-a714-e4f0-4fb2-1b5c6d00d8d6-e6c45078,
  #w-node-_8f4c1dd0-ec01-a7be-ad84-fb0eb94b14ae-e6c45078,
  #w-node-_1b113532-5ab0-2adb-6214-a6cbdb9e24f0-e6c45078,
  #w-node-_62dc6c13-ae84-6917-98cc-eaeda33557ec-e6c45078,
  #w-node-cbf11b02-2beb-c203-9277-11f2ffbdd825-e6c45078,
  #w-node-_261bdd1d-bf66-55d1-45a6-02e737962421-e6c45078,
  #w-node-_9c115f4a-65db-f39a-6f2e-afb929bac51e-e6c45078,
  #w-node-cf4f7b59-ce0c-a758-7174-d6582443b7f0-e6c45078,
  #w-node-_42a60bf9-7d8f-b072-96be-f71d34fa0ae9-e6c45078,
  #w-node-e4965367-e88a-a1a8-01f2-16e95084a796-e6c45078,
  #w-node-_1c7b3a5e-76ce-71e3-d2f9-49173fbd34e6-e6c45078,
  #w-node-_82ad273b-2568-00e9-89c8-071c52d19303-e6c45078,
  #w-node-f7730f64-2558-60e8-166e-1d151ab097d3-e6c45078,
  #w-node-_48cb101e-775f-65c7-ac5a-f33f762830d3-e6c45078,
  #w-node-_8c886485-5081-4d98-37cb-ec55fcfce186-e6c45078,
  #w-node-db9d9fb8-de38-0dd1-83be-d306012a0c06-e6c45078,
  #w-node-_9ed3a3c4-724b-e3d3-7917-316d604c0207-e6c45078,
  #w-node-_950d8cff-f626-dc4a-62ea-1d2ae44588c7-e6c45078,
  #w-node-_4340200a-142a-2212-a78e-3da3d31320a0-e6c45078,
  #w-node-_81fc589f-51d9-838d-34f6-b4df2e5205d1-e6c45078,
  #w-node-d48e80be-9462-3dc6-e15d-219aed378293-e6c45078,
  #w-node-_73832c85-72e6-3da2-9d20-248a4d8efc36-e6c45078,
  #w-node-e705f197-7ee6-0250-cce0-b73dc93c74e1-e6c45078,
  #w-node-_649af37b-d80d-302f-7968-77b73f066f5c-e6c45078,
  #w-node-d64ce8e5-3526-11fc-254e-9467e0f63b4a-e6c45078,
  #w-node-_650ba7f3-1ab2-79af-7956-2bbf9dda8dbc-e6c45078,
  #w-node-d339e816-e2ed-1e7d-f60f-ef21ca69e2c4-e6c45078,
  #w-node-_5444c71d-1f78-563e-c9fd-935982e627b6-e6c45078,
  #w-node-_01c26295-9732-6519-7f26-beed8f6db03a-e6c45078,
  #w-node-_03d975f3-2c2b-a5ee-9263-a27233e712e7-e6c45078 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: auto;
    justify-self: auto;
  }
}
