/* for hiding process is not defined bug from console */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.lang-dropdown .fa-globe {
  margin-right: 7px;
  color: var(--heading);
  font-size: 18px;
}

/* country flag css */
.flag {
  width: 25px;
  height: 20px;
  background-image: url("https://raw.githubusercontent.com/bl00mber/react-phone-input-2/master/src/style/common/high-res.png");
  background-repeat: no-repeat;
}
.ad {
  background-position: -48px -24px;
}
.ae {
  background-position: -72px -24px;
}
.af {
  background-position: -96px -24px;
}
.ag {
  background-position: -120px -24px;
}
.ai {
  background-position: -144px -24px;
}
.al {
  background-position: -168px -24px;
}
.am {
  background-position: -192px -24px;
}
.an {
  background-position: -216px -24px;
}
.ao {
  background-position: -240px -24px;
}
.aq {
  background-position: -264px -24px;
}
.ar {
  background-position: -288px -24px;
}
.as {
  background-position: -312px -24px;
}
.at {
  background-position: -336px -24px;
}
.au {
  background-position: -360px -24px;
}
.aw {
  background-position: -384px -24px;
}
.ax {
  background-position: 0px -48px;
}
.az {
  background-position: -24px -48px;
}
.ba {
  background-position: -48px -48px;
}
.bb {
  background-position: -72px -48px;
}
.bd {
  background-position: -96px -48px;
}
.be {
  background-position: -120px -48px;
}
.bf {
  background-position: -144px -48px;
}
.bg {
  background-position: -168px -48px;
}
.bh {
  background-position: -192px -48px;
}
.bi {
  background-position: -216px -48px;
}
.bj {
  background-position: -240px -48px;
}
.bl {
  background-position: -264px -48px;
}
.bm {
  background-position: -288px -48px;
}
.bn {
  background-position: -312px -48px;
}
.bo {
  background-position: -336px -48px;
}
.br {
  background-position: -360px -48px;
}
.bs {
  background-position: -384px -48px;
}
.bt {
  background-position: 0px -72px;
}
.bw {
  background-position: -24px -72px;
}
.by {
  background-position: -48px -72px;
}
.bz {
  background-position: -72px -72px;
}
.ca {
  background-position: -96px -72px;
}
.cc {
  background-position: -120px -72px;
}
.cd {
  background-position: -144px -72px;
}
.cf {
  background-position: -168px -72px;
}
.cg {
  background-position: -192px -72px;
}
.ch {
  background-position: -216px -72px;
}
.ci {
  background-position: -240px -72px;
}
.ck {
  background-position: -264px -72px;
}
.cl {
  background-position: -288px -72px;
}
.cm {
  background-position: -312px -72px;
}
.cn {
  background-position: -336px -72px;
}
.co {
  background-position: -360px -72px;
}
.cr {
  background-position: -384px -72px;
}
.cu {
  background-position: 0px -96px;
}
.cv {
  background-position: -24px -96px;
}
.cw {
  background-position: -48px -96px;
}
.cx {
  background-position: -72px -96px;
}
.cy {
  background-position: -96px -96px;
}
.cz {
  background-position: -120px -96px;
}
.de {
  background-position: -144px -96px;
}
.dj {
  background-position: -168px -96px;
}
.dk {
  background-position: -192px -96px;
}
.dm {
  background-position: -216px -96px;
}
.do {
  background-position: -240px -96px;
}
.dz {
  background-position: -264px -96px;
}
.ec {
  background-position: -288px -96px;
}
.ee {
  background-position: -312px -96px;
}
.eg {
  background-position: -336px -96px;
}
.eh {
  background-position: -360px -96px;
}
.er {
  background-position: -384px -96px;
}
.es {
  background-position: 0px -120px;
}
.et {
  background-position: -24px -120px;
}
.eu {
  background-position: -48px -120px;
}
.fi {
  background-position: -72px -120px;
}
.fj {
  background-position: -96px -120px;
}
.fk {
  background-position: -120px -120px;
}
.fm {
  background-position: -144px -120px;
}
.fo {
  background-position: -168px -120px;
}
.fr {
  background-position: -192px -120px;
}
.ga {
  background-position: -216px -120px;
}
.gb {
  background-position: -240px -120px;
}
.gd {
  background-position: -264px -120px;
}
.ge {
  background-position: -288px -120px;
}
.gg {
  background-position: -312px -120px;
}
.gh {
  background-position: -336px -120px;
}
.gi {
  background-position: -360px -120px;
}
.gl {
  background-position: -384px -120px;
}
.gm {
  background-position: 0px -144px;
}
.gn {
  background-position: -24px -144px;
}
.gq {
  background-position: -48px -144px;
}
.gr {
  background-position: -72px -144px;
}
.gs {
  background-position: -96px -144px;
}
.gt {
  background-position: -120px -144px;
}
.gu {
  background-position: -144px -144px;
}
.gw {
  background-position: -168px -144px;
}
.gy {
  background-position: -192px -144px;
}
.hk {
  background-position: -216px -144px;
}
.hn {
  background-position: -240px -144px;
}
.hr {
  background-position: -264px -144px;
}
.ht {
  background-position: -288px -144px;
}
.hu {
  background-position: -312px -144px;
}
.ic {
  background-position: -336px -144px;
}
.id {
  background-position: -360px -144px;
}
.ie {
  background-position: -384px -144px;
}
.il {
  background-position: 0px -168px;
}
.im {
  background-position: -24px -168px;
}
.in {
  background-position: -48px -168px;
}
.iq {
  background-position: -72px -168px;
}
.ir {
  background-position: -96px -168px;
}
.is {
  background-position: -120px -168px;
}
.it {
  background-position: -144px -168px;
}
.je {
  background-position: -168px -168px;
}
.jm {
  background-position: -192px -168px;
}
.jo {
  background-position: -216px -168px;
}
.jp {
  background-position: -240px -168px;
}
.ke {
  background-position: -264px -168px;
}
.kg {
  background-position: -288px -168px;
}
.kh {
  background-position: -312px -168px;
}
.ki {
  background-position: -336px -168px;
}
.km {
  background-position: -360px -168px;
}
.kn {
  background-position: -384px -168px;
}
.kp {
  background-position: 0px -192px;
}
.kr {
  background-position: -24px -192px;
}
.kw {
  background-position: -48px -192px;
}
.ky {
  background-position: -72px -192px;
}
.kz {
  background-position: -96px -192px;
}
.la {
  background-position: -120px -192px;
}
.lb {
  background-position: -144px -192px;
}
.lc {
  background-position: -168px -192px;
}
.li {
  background-position: -192px -192px;
}
.lk {
  background-position: -216px -192px;
}
.lr {
  background-position: -240px -192px;
}
.ls {
  background-position: -264px -192px;
}
.lt {
  background-position: -288px -192px;
}
.lu {
  background-position: -312px -192px;
}
.lv {
  background-position: -336px -192px;
}
.ly {
  background-position: -360px -192px;
}
.ma {
  background-position: -384px -192px;
}
.mc {
  background-position: 0px -216px;
}
.md {
  background-position: -24px -216px;
}
.me {
  background-position: -48px -216px;
}
.mf {
  background-position: -72px -216px;
}
.mg {
  background-position: -96px -216px;
}
.mh {
  background-position: -120px -216px;
}
.mk {
  background-position: -144px -216px;
}
.ml {
  background-position: -168px -216px;
}
.mm {
  background-position: -192px -216px;
}
.mn {
  background-position: -216px -216px;
}
.mo {
  background-position: -240px -216px;
}
.mp {
  background-position: -264px -216px;
}
.mq {
  background-position: -288px -216px;
}
.mr {
  background-position: -312px -216px;
}
.ms {
  background-position: -336px -216px;
}
.mt {
  background-position: -360px -216px;
}
.mu {
  background-position: -384px -216px;
}
.mv {
  background-position: 0px -240px;
}
.mw {
  background-position: -24px -240px;
}
.mx {
  background-position: -48px -240px;
}
.my {
  background-position: -72px -240px;
}
.mz {
  background-position: -96px -240px;
}
.na {
  background-position: -120px -240px;
}
.nc {
  background-position: -144px -240px;
}
.ne {
  background-position: -168px -240px;
}
.nf {
  background-position: -192px -240px;
}
.ng {
  background-position: -216px -240px;
}
.ni {
  background-position: -240px -240px;
}
.nl {
  background-position: -264px -240px;
}
.no {
  background-position: -288px -240px;
}
.np {
  background-position: -312px -240px;
}
.nr {
  background-position: -336px -240px;
}
.nu {
  background-position: -360px -240px;
}
.nz {
  background-position: -384px -240px;
}
.om {
  background-position: 0px -264px;
}
.pa {
  background-position: -24px -264px;
}
.pe {
  background-position: -48px -264px;
}
.pf {
  background-position: -72px -264px;
}
.pg {
  background-position: -96px -264px;
}
.ph {
  background-position: -120px -264px;
}
.pk {
  background-position: -192px -264px;
}
.pl {
  background-position: -216px -264px;
}
.pn {
  background-position: -240px -264px;
}
.pr {
  background-position: -264px -264px;
}
.ps {
  background-position: -288px -264px;
}
.pt {
  background-position: -312px -264px;
}
.pw {
  background-position: -336px -264px;
}
.py {
  background-position: -360px -264px;
}
.qa {
  background-position: -384px -264px;
}
.ro {
  background-position: 0px -288px;
}
.rs {
  background-position: -24px -288px;
}
.ru {
  background-position: -48px -288px;
}
.rw {
  background-position: -72px -288px;
}
.sa {
  background-position: -96px -288px;
}
.sb {
  background-position: -120px -288px;
}
.sc {
  background-position: -144px -288px;
}
.sd {
  background-position: -168px -288px;
}
.se {
  background-position: -192px -288px;
}
.sg {
  background-position: -216px -288px;
}
.sh {
  background-position: -240px -288px;
}
.si {
  background-position: -264px -288px;
}
.sk {
  background-position: -288px -288px;
}
.sl {
  background-position: -312px -288px;
}
.sm {
  background-position: -336px -288px;
}
.sn {
  background-position: -360px -288px;
}
.so {
  background-position: -384px -288px;
}
.sr {
  background-position: 0px -312px;
}
.ss {
  background-position: -24px -312px;
}
.st {
  background-position: -48px -312px;
}
.sv {
  background-position: -72px -312px;
}
.sy {
  background-position: -96px -312px;
}
.sz {
  background-position: -120px -312px;
}
.tc {
  background-position: -144px -312px;
}
.td {
  background-position: -168px -312px;
}
.tf {
  background-position: -192px -312px;
}
.tg {
  background-position: -216px -312px;
}
.th {
  background-position: -240px -312px;
}
.tj {
  background-position: -264px -312px;
}
.tk {
  background-position: -288px -312px;
}
.tl {
  background-position: -312px -312px;
}
.tm {
  background-position: -336px -312px;
}
.tn {
  background-position: -360px -312px;
}
.to {
  background-position: -384px -312px;
}
.tr {
  background-position: 0px -336px;
}
.tt {
  background-position: -24px -336px;
}
.tv {
  background-position: -48px -336px;
}
.tw {
  background-position: -72px -336px;
}
.tz {
  background-position: -96px -336px;
}
.ua {
  background-position: -120px -336px;
}
.ug {
  background-position: -144px -336px;
}
.us {
  background-position: -168px -336px;
}
.uy {
  background-position: -192px -336px;
}
.uz {
  background-position: -216px -336px;
}
.va {
  background-position: -240px -336px;
}
.vc {
  background-position: -264px -336px;
}
.ve {
  background-position: -288px -336px;
}
.vg {
  background-position: -312px -336px;
}
.vi {
  background-position: -336px -336px;
}
.vn {
  background-position: -360px -336px;
}
.vu {
  background-position: -384px -336px;
}
.wf {
  background-position: 0px -360px;
}
.ws {
  background-position: -24px -360px;
}
.ye {
  background-position: -48px -360px;
}
.za {
  background-position: -96px -360px;
}
.zm {
  background-position: -120px -360px;
}
.zw {
  background-position: -144px -360px;
}

.changeLanguage .dropbtn {
  background-color: transparent;
  /* padding-left: 10px; */
  border: none;
  cursor: pointer;
  /* margin-right: 20px; */
  font-size: 12px;
  color: #162542;
  text-transform: uppercase;
  font-weight: 500;
}
.changeLanguage .dropdown img {
  display: initial;
}

.changeLanguage .dropdown {
  position: relative;
  display: inline-block;
  background-color: transparent;
  margin-top: -5px;
  display: inherit !important;
}

.changeLanguage .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 25px;
}
.changeLanguage .dropdown-content button,
.changeLanguage .dropdown-content div {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
  text-align: left;
}
.changeLanguage .dropdown-content button:hover,
.changeLanguage .dropdown-content div:hover {
  background-color: #f2f2f2;
  width: 100%;
}
.changeLanguage .dropdown-content button:hover {
  background-color: #f2f2f2;
  width: 100%;
}

.changeLanguage .dropdown-content a img {
  margin-right: 7px;
}
.changeLanguage .dropdown-content a:hover {
  background-color: #f2f2f2;
}

.changeLanguage .dropdown:hover .dropdown-content {
  display: block;
}

.theme-dark .changeLanguage .dropdown-content {
  background-color: #1a1c23;
  color: #e5e7eb;
}
.theme-dark .changeLanguage .dropdown-content a {
  color: #e5e7eb;
}
.theme-dark .changeLanguage .dropdown-content a:hover {
  background-color: #121317;
}

.theme-dark .changeLanguage .dropbtn {
  color: #e5e7eb;
}

.react-responsive-modal-root {
  z-index: 20001 !important;
}

/* @media (min-width: 1920px) {
  .container {
    max-width: 1920px !important;
  }
} */

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="file"] {
  display: none;
}

.btn-red {
  background-color: red !important;
}
.btn-orange {
  background-color: orange !important;
}
.btn-gray {
  background-color: #e5e7eb !important;
}

.hamburger-menu {
  background: #007533;
  border: 0;
  color: #fff;
  font-size: 14px;
  height: 64px;
  margin: 0;
  padding: 0 21.33333333px;
  width: 64px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
